<template>
  <v-app :class="{ 'night-vision': isNightVision }">
    <v-app-bar app clipped-left dense>
      <v-tabs
        :next-icon="mdiChevronRight"
        :prev-icon="mdiChevronLeft"
        show-arrows
        optional
      >
        <v-tabs-slider />
        <v-tab :to="{ name: 'Documents' }">Documents</v-tab>
        <v-tab :to="{ name: 'TacticalReference' }">Tactical Reference</v-tab>
        <v-tab :to="{ name: 'Map' }">Maps</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-switch
        class="mr-5"
        v-model="isNightVision"
        label="NV"
        dense
        hide-details
      ></v-switch>
      <v-btn v-if="oidcIsAuthenticated" @click="removeOidcUser">
        Sign Out
        <v-img
          v-if="oidcUser.picture"
          :src="oidcUser.picture"
          class="ml-3"
          max-height="28px"
          max-width="28px"
          contain
        />
      </v-btn>
      <v-btn v-else @click="authenticateOidc">Sign In</v-btn>
    </v-app-bar>
    <v-main>
      <router-view v-if="hasAccess" />
      <authentication-required-component v-else />
    </v-main>
    <v-banner
      v-if="$vuetify.breakpoint.mobile && installationPrompt"
      color="info"
      class="installation-banner"
      :icon="mdiHomePlus"
    >
      The EFB can be installed to your Home Screen...
      <template #actions>
        <v-btn text @click="dismiss">Dismiss</v-btn>
        <v-btn text @click="install">Install</v-btn>
      </template>
    </v-banner>
    <v-snackbar bottom right :value="updateExists" :timeout="-1">
      An update is available...
      <v-btn text @click="refreshApp">Update</v-btn>
    </v-snackbar>
  </v-app>
</template>

<style scoped>
.installation-banner {
  z-index: 220;
}

.night-vision {
  filter: invert();
}
</style>

<script>
import AuthenticationRequiredComponent from "@/components/AuthenticationRequiredComponent.vue";
import serviceWorkerUpdate from "@/mixins/serviceWorkerUpdate";
import { isDocumentRoute } from "@/router";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiHomePlus,
  mdiLockOutline,
} from "@mdi/js";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  components: { AuthenticationRequiredComponent },
  name: "App",
  computed: {
    ...mapGetters([
      "categories",
      "category",
      "oidcIsAuthenticated",
      "oidcUser",
    ]),
    hasAccess: function () {
      const route = this.$route;
      if (
        !this.oidcIsAuthenticated &&
        isDocumentRoute(route) &&
        this.category(route.params?.category)?.private
      ) {
        return false;
      }
      return true;
    },
  },
  data: () => ({
    mdiChevronLeft,
    mdiChevronRight,
    mdiHomePlus,
    mdiLockOutline,
    isNightVision: false,
  }),
  methods: {
    ...mapActions(["authenticateOidc", "removeOidcUser"]),
  },
  mixins: [serviceWorkerUpdate],
});
</script>
