<template>
  <v-tabs centered show-arrows>
    <v-tabs-slider></v-tabs-slider>
    <v-tab>
      <v-icon left>{{ mdiAirport }}</v-icon> Data
    </v-tab>
    <v-tab v-for="(chart, i) in location.charts" :key="i">
      <v-icon left>{{ mdiMap }}</v-icon> {{ chart.name }}
    </v-tab>
    <v-tab-item>
      <v-container>
        <v-row>
          <map-location-detail
            title="Approach"
            :value="location.approachFrequencyUhf"
          />
          <map-location-detail
            title="Tower UHF"
            :value="location.towerFrequencyUhf"
          />
          <map-location-detail
            title="Tower VHF"
            :value="location.towerFrequencyVhf"
          />
          <map-location-detail
            title="Ground"
            :value="location.groundFrequencyUhf"
          />
          <map-location-detail
            title="Ops"
            :value="location.operationsFrequencyUhf"
          />
          <map-location-detail
            title="ATIS"
            :value="location.atisFrequencyUhf"
          />
          <map-location-detail
            title="TACAN"
            :value="location.tacanChannel + location.tacanBand"
          />
          <map-location-detail title="RANGE" :value="location.range" />
          <map-location-detail
            title="ILS RWY 1"
            :value="location.runway1IlsFrequency"
          />
          <map-location-detail
            title="ILS RWY 2"
            :value="location.runway2IlsFrequency"
          />
          <map-location-detail
            title="ILS RWY 3"
            :value="location.runway3IlsFrequency"
          />
          <map-location-detail
            title="ILS RWY 4"
            :value="location.runway4IlsFrequency"
          />
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item v-for="(chart, i) in location.charts" :key="i">
      <v-img
        class="chart-image mx-auto"
        contain
        :max-height="defaultImageHeight"
        :min-height="defaultImageHeight"
        :src="chartUrl(chart)"
      >
        <template #placeholder>
          <v-skeleton-loader
            :max-height="defaultImageHeight"
            :min-height="defaultImageHeight"
            type="card, image, image"
          ></v-skeleton-loader>
        </template>
      </v-img>
    </v-tab-item>
  </v-tabs>
</template>

<style scoped>
.chart-image >>> .v-image__image--contain {
  background-position: center top !important;
}
</style>

<script>
import { baseUrl } from "@/appUrl";
import MapLocationDetail from "@/components/MapLocationDetail.vue";
import { mdiAirport, mdiMap } from "@mdi/js";

export default {
  components: {
    MapLocationDetail,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultImageHeight: Math.min(1100, this.$vuetify.breakpoint.height), // All charts have a height of 1100
      mdiAirport,
      mdiMap,
    };
  },
  methods: {
    chartUrl(chart) {
      return new URL("./data/maps/" + chart.filePath, baseUrl).toString();
    },
  },
};
</script>
