<template>
  <span>
    <v-navigation-drawer
      app
      clipped
      fixed
      width="180"
      :mini-variant.sync="navigationDrawerPinned"
      permanent
      touchless
    >
      <span v-for="(category, i) in categories()" :key="i">
        <v-list dense nav subheader>
          <v-subheader>{{ category.name }}</v-subheader>
          <document-list
            v-for="(document, j) in documents(category.alias)"
            :key="j"
            :id="document.id"
          />
        </v-list>
        <v-divider />
      </span>
      <template #append>
        <v-toolbar dense>
          <v-btn
            ripple
            plain
            icon
            @click="navigationDrawerPinned = !navigationDrawerPinned"
            v-if="$vuetify.breakpoint.mdAndDown"
          >
            <v-icon v-if="navigationDrawerPinned">{{ mdiChevronRight }}</v-icon>
            <v-icon v-else>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </span>
</template>

<script lang="ts">
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Vue from "vue";
import { mapGetters } from "vuex";
import DocumentList from "@/components/DocumentList.vue";

export default Vue.extend({
  components: {
    DocumentList,
  },
  computed: {
    ...mapGetters(["categories", "documents"]),
  },
  data() {
    return {
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
      navigationDrawerPinned: false,
    };
  },
});
</script>
