<template>
  <v-container v-if="pageCount">
    <div v-for="pageIndex in pageCount" :key="uri + pageIndex">
      <pdf
        v-if="pageIndex === page"
        :id="pageIndex"
        :src="data"
        :annotation="annotation"
        :page="pageIndex"
        :resize="resize"
        :scale.sync="scale"
        @link-clicked="handlePdfLink"
      >
        <template slot="loading">
          <v-skeleton-loader type="card, article, actions"></v-skeleton-loader>
        </template>
      </pdf>
    </div>
    <v-bottom-navigation app fixed grow>
      <v-pagination
        v-model="selectedPage"
        :length="pageCount"
        :total-visible="10"
      >
      </v-pagination>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import router from "@/router";
import pdf from "pdfvuer";

export default {
  components: {
    pdf,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dynamicKey() {
      return this.uri;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    uri() {
      return this.$store.getters.documentLocation(this.id);
    },
    selectedPage: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      get() {
        return this.page;
      },
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      set(selectedPage) {
        router.push({
          name: "Document",
          params: {
            id: this.id,
            page: selectedPage,
          },
        });
      },
    },
  },
  watch: {
    uri: function () {
      this.loadData();
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      data: undefined,
      dataUri: undefined,
      pageCount: 0,
      annotation: true,
      resize: true,
      scale: "page-width",
    };
  },
  methods: {
    handlePdfLink(params) {
      const page = params.pageNumber;

      if (!page) {
        return;
      }

      if (page === this.selectedPage) {
        return;
      }

      this.selectedPage = page;
    },
    loadData() {
      // No sense to proceed, if URI has not been set
      if (!this.uri) {
        return;
      }

      // Don't want to load the same URI again
      if (this.uri === this.dataUri) {
        return;
      }

      // Memorize the URI that is currently loaded
      this.dataUri = this.uri;

      // Create the loading task - we'll use this in the component
      this.data = pdf.createLoadingTask(this.uri);

      // Load the data and memorize the page count
      this.data.then((pdf) => {
        this.pageCount = pdf.numPages;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
